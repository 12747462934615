@import "_config.scss";

$grey: #ababab;
$mainText: #212529;
$mainHeader: #384057;
$fieldsColor: #aeb2c3;

$linksColor: #000000;
$linksColorHover: #212529;

$blueBG: #131F37;
$greenAccent: #BAE8AB;

$defBorderRadius: 19px;

@font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('../fonts/Inter-Medium.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('../fonts/SourceSerifPro-Regular.otf');
    font-weight: normal;
    font-style: normal;
}



html {
    font-size: 12px;
    font-family: "Source Serif Pro", serif;
    color: $mainText;
}

h1, h2, h3, h4, h5, h6 {
    color: #384057;
}

div.App {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $blueBG;
}

div.MDInterface {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    
    div.header {
        padding: 10px;
        display: flex;
        flex-direction: row;

        div.logo,
        div.logout {
            flex-grow: 0;
            flex-shrink: 0;

            a,
            button.btn-link {
                color: #fff;
            }
        }

        div.searchContainer {
            flex-grow: 1;
            padding: 10px;
            display: flex;
            flex-direction: row;
            gap: 10px;

            .searchControl {
                flex-grow: 1;
            }

            label {
                padding-left: 10px;
                color: #fff;
                font-weight: bold;
            }

            textarea {
                border-radius: $defBorderRadius;
                height: 38px;
                font-size: 14px;
                padding: 7px 10px;
                resize: none;
            }

            textarea:focus {
                height: 38px;
            }

            input {
                border-radius: $defBorderRadius;
            }

            .datesContainer {
                display: flex;
                flex-direction: row;
                
                > div {
                    flex-grow: 1;
                    
                }

                .toDate input {
                    border-radius: 0 $defBorderRadius $defBorderRadius 0;
                }
    
                .fromDate input {
                    border-radius: $defBorderRadius 0 0 $defBorderRadius;
                }
    
                .react-datepicker__navigation-icon--previous,
                .react-datepicker__navigation-icon--next {
                    display: none;
                }
            }

            
        }

        .searchButtonContainer {
            padding-top: 23px;

            button {
                height: 38px;
                border-radius: $defBorderRadius;
                padding: 4px 15px;
                font-size: 14px;
            }
        }

        .sourceButtonContainer {
            button {
                height: 38px;
                border-radius: $defBorderRadius;
                padding: 4px 15px;
                font-size: 14px;
            }
        }
    }

    .docCard {
        padding: 10px 0;
        border-bottom: 1px solid $grey;

        .caption {
            font-size: 14px;
        }

        .fields {
            font-size: 11px;
            display: flex;
            flex-direction: row;
            margin-top: 5px;
            color: $fieldsColor;
            gap: 15px;
        }
    }

    .docCard.open {
        padding-left: 12px;
        h4 {
            font-weight: 700;
        }

        a, a:visited {
            color: $linksColor;
        }

        a:hover {
            color: $linksColorHover;
        }

        .mdSelector {
            margin-top: 10px;
            h5 {
                font-size: 10px;
                color: #757575;
            }
            
            button {
                text-align: left;
                padding: 2px 0;
                color: $linksColor;
                display: block;
            }

            button:hover {
                color: $linksColorHover;
            }
        }
    }

    .lessRelevantButton {
        border-radius: $defBorderRadius;
    }

    .intro {
        margin-top: 100px;

        h2 {
            color: #fff;
            font-weight: normal;
            font-size: 32px;
            line-height: 52px;

            .highlighted {
                background-color: $greenAccent;
                color: #000;
            }
        }
    }
}

div.MDInterface.noDoc .mainContent {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;

    .docCard {
        .caption {
            color: #fff;
        }

        .relevance {
            flex-grow: 1;
            color: $greenAccent;
        }
    }
}

.relevance {
    display: none;
}

div.MDInterface.withDoc .mainContent {
    background-color: #F3F3F3;
    flex-grow: 1;
    flex-shrink: 1;
    height: 1px;

    .docsPanel {
        display: flex;
        flex-direction: row;
        height: 100%;

        .listPanel {
            flex-grow: 1;
            flex-shrink: 1;
            width: 1px;
            max-width: 400px;

            height: 100%;
            padding: 20px 16px;

            display: flex;
            flex-direction: column;
            overflow-x: visible;

            .openDocItems {
                flex-shrink: 1;
                max-height: 60%;
                
                h4 {
                    font-size: 16px;
                    color: #2c2c2c;
                }
            }

            .docsList {
                margin-top: 10px;
                flex-shrink: 1;
                overflow-y: auto;
                overflow-x: visible;
                margin-left: -16px;
                margin-right: -16x;
                padding-left: 16px;
                padding-right: 16px;
            
            }
        }

        .DocViewer {
            flex-grow: 2;
            flex-shrink: 2;
            width: 1px;
            overflow-y: auto;
            position: relative;
            
            
        }

        .scrollBarItems {
            width: 20px;
            position: relative;

            .heatmapLink {
                position: absolute;
                width: 10px;
            }
        }

        .chatContainer {
            flex-grow: 1;
            flex-shrink: 1;
            width: 1px;
            overflow-y: auto;
            max-width: 300px;
            display: flex;
            flex-direction: column;
            justify-content: end;
        }
    }
}

input[type=text],
input[type=password],
input[type=email],
div.react-datepicker__input-container > input {
    padding: 9px 12px;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.375rem;
}

div.relevantContent {
    display: flex;
    flex-direction: row;
    height: 100%;
}

div.relevantContent > div {
    max-height: 100%;
    overflow-y: auto;
}

div.DocViewer {
    
    .contents {
        margin-top: 40px;
        font-size: 18px;

        .page {
            background: #fff;
            margin-bottom: 20px;
            margin-left: 10px;
            margin-right: 10px;
            border-radius: $defBorderRadius;
            overflow: hidden;
            padding-top: 50px;
            padding-bottom: 50px;

            .page_headers {
                margin-top: -50px;
                margin-bottom: 30px;
            }

            .page_footers {
                margin-top: 30px;
                margin-bottom: -50px;
            }
            
            .page_footers,
            .page_headers {
                display: flex;
                flex-direction: row;
                gap: 10px;
                background: #FAFAFA;
                padding: 10px 30px;
                font-size: 10px;

                .paragraph {
                    margin-bottom: 0;
                }
            }

            .page_footnotes {
                border-top: 1px solid $grey;
                padding: 10px 10px 0 10px;
                margin-left: -10px;
                margin-right: -10px;
                font-size: 14px;
            }

        }

        .paragraph {
            margin-left: 10px;
            margin-right: 10px;

            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            border-radius: 6px;
            overflow: hidden;
            align-items: stretch;

            
            .relBar {
                width: 10px;
                flex-shrink: 0;
                flex-grow: 0;

                .hmbar {
                    display: block;
                    height: 100%;
                    width: 5px;
                }
            }
            .section {
                width: 60px;
                flex-shrink: 0;
                flex-grow: 0;
                text-align: right;
                padding-right: 10px;
            }

            .actions {
                width: 60px;
                flex-shrink: 0;
                flex-grow: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 5px;
                
                button {
                    background: none;
                    border: none;
                    color: $grey;
                    display: none;
                    border-radius: 10px;
                    flex-grow: 1;
                    background-color: #E5E5E5;
                    padding: 0 9px;

                    svg {
                        height: 18px;
                    }
                

                    :hover {
                        background: none;
                        border: none;
                    }
                }
            }

            .content {
                flex-grow: 1;
            }
        }

        .paragraph:hover {
            background-color: #F4F3F3;

            .actions {
                button {
                    display: inline-block;
                }
            }
        }

        
    }
}

div.chat {
    padding: 20px;

}

div.chat div.chatItem {
    padding-right: 180px;
}

div.chat div.chatItem.user {
    padding-left: 180px;
    padding-right: 0;
}

div.chat div.chatItem > div {
    white-space: pre-line;
    background: #eee;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

div.chat div.chatItem.sys {
    font-size: 8px;
    font-style: italic;
}

div.chat div.chatItem.user > div {
    background: #090;
    color: #fff;
}

div.chat div.chatItem span.role {
    display: block;
    text-transform: capitalize;
    text-align: right;
    font-size: 8px;
    margin-bottom: 4px;
}



.ChatButton {
    border-radius: 20px;
    background: #8cacf6;
    padding: 20px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    background: #8cacf6;

    svg {
        width: 40px;
        height: 40px;
    }
}

.ChatWindow {
    padding: 10px;
    bottom: 30px;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    
    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .panel {
        overflow-y: auto;
        flex-grow: 1;
    }
    
    .dialogItem {
        margin-bottom: 10px;
        border-radius: 10px;
        background: #fff;
        padding: 10px 16px;
    

        .role {
            font-size: 12px;
            color: #0054D1;
            font-weight: bold;
            
        }
    }

    .dialogItem.user {
        margin-left: 50px;
        background: #f8f8f8;
        color: #585858;
        font-style: italic;

        .role {
            font-style: normal;
            color: #000;
        }
    }

    .dialogItem:nth-child(2n + 1) {
        text-align: right;
    }

    textarea {
        width: 100%;
        border-radius: 10px;
        padding: 10px;
        height: 70px;
        resize: none;
    }

    div.parScores {
        .links {
            overflow-x: auto;
            flex-wrap: nowrap;
            flex-direction: row;
            display: flex;
            
            button {
                display: inline-block;
                white-space: nowrap;
            
            }
        }
    }

    .askButton {
        border-radius: $defBorderRadius;
    }
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
    display: none;
}

