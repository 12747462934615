div.login {
    background: #131F37;
}

div.loginForm {
    background: #ccc;
    width: 800px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 100px 150px;
    border-radius: 19px;
}