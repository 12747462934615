@import "_config.scss";

div.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $fadedBackground;
  text-align: center;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden; 
  z-index: 130000;
}

div.loadingSpinnerContainer div svg
{
    overflow: show;
}

div.loadingSpinnerContainer.show {
  opacity: 1;
  visibility: visible; 
}

div.loadingSpinnerContainer div {
  margin: 0;
   position: absolute;           
   top: 50%;                     
   transform: translate(0, -50%);
   display: inline-block;
}